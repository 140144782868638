var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "w-100 grey lighten-4", attrs: { "fill-height": "" } },
    [
      _c(
        "main",
        { staticClass: "w-100 my-3 fill-height mx-auto" },
        [
          _c("v-container", { attrs: { fluid: "", "fill-height": "" } }, [
            _c(
              "section",
              {
                staticClass:
                  "w-100 d-flex align-items-center justify-content-center fill-height",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-6 col-12 rounded-lg shadow-sm d-flex flex-column white fill-height",
                  },
                  [
                    _c("div", { staticClass: "mt-5" }, [
                      _c("span", { staticClass: "f24 font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$t("Complete your profile"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "f16 grey--text text--darken-1 font-weight-normal",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "Please enter your information correctly in the field below"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        staticClass: "mt-2",
                        attrs: { "lazy-validation": "" },
                        model: {
                          value: _vm.valid,
                          callback: function ($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-between mt-auto",
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "text-center mt-4",
                              attrs: {
                                outlined: "",
                                type: "text",
                                label: _vm.$t("Enter your name"),
                                rules: [(v) => !!v],
                                required: "",
                              },
                              model: {
                                value: _vm.firstName,
                                callback: function ($$v) {
                                  _vm.firstName = $$v
                                },
                                expression: "firstName",
                              },
                            }),
                            _c("v-text-field", {
                              staticClass: "text-center mt-1",
                              attrs: {
                                outlined: "",
                                type: "text",
                                label: _vm.$t("Enter your last name"),
                                rules: [(v) => !!v],
                                required: "",
                              },
                              model: {
                                value: _vm.lastName,
                                callback: function ($$v) {
                                  _vm.lastName = $$v
                                },
                                expression: "lastName",
                              },
                            }),
                            _c("v-text-field", {
                              staticClass: "text-center mt-1",
                              attrs: {
                                outlined: "",
                                type: "number",
                                label: _vm.$t("Enter your age"),
                                rules: [(v) => !!v],
                                required: "",
                              },
                              model: {
                                value: _vm.age,
                                callback: function ($$v) {
                                  _vm.age = $$v
                                },
                                expression: "age",
                              },
                            }),
                            _c("v-select", {
                              staticClass: "mt-1",
                              attrs: {
                                label: _vm.$t("Choose your gender"),
                                outlined: "",
                                required: "",
                                "small-chips": "",
                                "no-data-text": _vm.$t("No data"),
                                items: _vm.genderItems,
                                "item-text": "text",
                                "item-value": "value",
                                rules: [(v) => !!v],
                              },
                              model: {
                                value: _vm.gender,
                                callback: function ($$v) {
                                  _vm.gender = $$v
                                },
                                expression: "gender",
                              },
                            }),
                            _c("v-autocomplete", {
                              staticClass: "mt-1",
                              attrs: {
                                label: _vm.$t("Choose your city"),
                                items: _vm.cities,
                                "item-text": "name",
                                "item-value": "name",
                                outlined: "",
                                required: "",
                                "small-chips": "",
                                "no-data-text": _vm.$t("No data"),
                                rules: [(v) => !!v],
                              },
                              model: {
                                value: _vm.chooseCity,
                                callback: function ($$v) {
                                  _vm.chooseCity = $$v
                                },
                                expression: "chooseCity",
                              },
                            }),
                            _c("div", { staticClass: "mt-1" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-2 d-flex flex-row align-items-stretch",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "col-8 flex-fill mx-2 13",
                                      attrs: {
                                        color: "primary",
                                        large: "",
                                        loading: _vm.submit_loading,
                                        disabled: _vm.submit_loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.validate()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Register")) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "col-4 text-white flex-fill mx-2 f13",
                                      attrs: {
                                        color: "red",
                                        large: "",
                                        loading: _vm.submit_loading,
                                        disabled: _vm.submit_loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.backToAuth()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Canceled")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }