<template>
  <v-app fill-height class="w-100 grey lighten-4">
    <main class="w-100 my-3 fill-height mx-auto">
      <v-container fluid fill-height>
        <section class="w-100 d-flex align-items-center justify-content-center fill-height">
          <div class="col-sm-6 col-12 rounded-lg shadow-sm d-flex flex-column white fill-height">
            <div class="mt-5">
              <span class="f24 font-weight-bold">{{ $t('Complete your profile') }}</span>
            </div>

            <div class="mt-2">
              <span class="f16 grey--text text--darken-1 font-weight-normal">
                {{ $t('Please enter your information correctly in the field below') }}
              </span>
            </div>

            <v-form ref="form" v-model="valid" lazy-validation class="mt-2">
              <div class="d-flex flex-column justify-content-between mt-auto">
                <v-text-field outlined v-model="firstName" type="text" :label="$t('Enter your name')"
                  class="text-center mt-4" :rules="[(v) => !!v]" required />

                <v-text-field outlined v-model="lastName" type="text" :label="$t('Enter your last name')"
                  class="text-center mt-1" :rules="[(v) => !!v]" required />

                <v-text-field outlined v-model="age" type="number" :label="$t('Enter your age')"
                  class="text-center mt-1" :rules="[(v) => !!v]" required />

                <v-select class="mt-1" :label="$t('Choose your gender')" v-model="gender" outlined required small-chips
                  :no-data-text="$t('No data')" :items="genderItems" item-text="text" item-value="value"
                  :rules="[(v) => !!v]">
                </v-select>
                <v-autocomplete class="mt-1" :label="$t('Choose your city')" v-model="chooseCity" :items="cities"
                  item-text="name" item-value="name" outlined required small-chips :no-data-text="$t('No data')"
                  :rules="[(v) => !!v]">
                </v-autocomplete>

                <div class="mt-1">
                  <div class="mt-2 d-flex flex-row align-items-stretch">
                    <v-btn color="primary" large class="col-8 flex-fill mx-2 13"
                           @click="validate()" :loading="submit_loading"
                           :disabled="submit_loading">
                      {{ $t('Register') }}
                    </v-btn>

                    <v-btn color="red" large class="col-4 text-white flex-fill mx-2 f13" @click="backToAuth()"
                           :loading="submit_loading"
                           :disabled="submit_loading">
                      {{ $t('Canceled') }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
        </section>
      </v-container>
    </main>
  </v-app>
</template>
<script>
import cityDATA from "@/assets/city.json";
import axios from "@/http/httpApplication.js";
import apiService from "@/http/ApiService";
export default {
  data() {
    return {
      submit_loading: false,

      valid: false,

      cities: cityDATA,
      firstName: "",
      lastName: "",
      age: "",
      chooseCity: "",
      gender: "",
      genderItems: [
        { text: this.$t('Man'), value: "MAN" },
        { text: this.$t('Woman'), value: "WOMAN" },
        { text: this.$t('Other'), value: "OTHER" },
      ],
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.AddNewUser();
      }
    },
    AddNewUser() {
      this.submit_loading = true;
      axios({
        url: `${process.env.VUE_APP_API_URL}/client/user/signup`,
        method: "post",
        data: {
          firstName: this.firstName,
          lastName: this.lastName,
          gender: this.gender,
          livingCity: this.chooseCity,
          age: this.age,
          mobileNumber: this.$store.getters.mobileNumber,
          roleCode: "3",
        },
      })
        .then((response) => {
          this.submit_loading = false;
          if (response.status == 200) {
            apiService.saveFcmTokenClient(this.$store.getters.firebaseToken);
            this.getNotificationCount();
            this.$store.commit("update_authenticated", true);
            this.$router.push("/c/home");
          }
        })
        .catch((err) => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    },
    getNotificationCount() {
      apiService.getNotificationCount()
          .then((response) => {
            this.$store.commit("update_notificationCount", response.data.count);
          })
          .catch((err) => {
            console.error(err);
          });
    },
    backToAuth() {
      this.$store.dispatch("logout");
      this.$router.push("/auth")
    },
  },
  created() {
    if (this.$store.getters.token && this.$store.getters.authenticated) {
      if (this.$store.getters.clientType == "CLIENT") {
        this.$router.push("/c/home");
      } else if (this.$store.getters.clientType == "DOCTOR") {
        this.$router.push("/s/home");
      }
    }
  },
};
</script>
<style scoped>
#box-shadow {
  box-shadow: 20px 20px 100px #e4f7ff;
}
</style>
